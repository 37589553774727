// @ts-ignore


import {
    Collapse,
    Nav,
    Navbar, NavbarBrand, NavbarToggler,
    NavItem,
    NavLink,

} from "reactstrap";
import logoGetVak from "../../static/img/GetVac/getvac.png";
import React, {useState} from "react";


function HeaderGetVak(): JSX.Element {
    const [toggle, setToggle] = useState(false);
    const toCuerpo = () => {
        const anchor = document.getElementById('cuerpo-content-index')
        if (anchor !== null){
            console.log('cuerpo')
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }
    const toContact = () => {
        const anchor = document.getElementById('contact-content-index')
        if (anchor !== null){
            console.log('contacto')
            anchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }
    const toSomos = () => {
        const anchor = document.getElementById('quienes-somos-index')
        if (anchor !== null){
            console.log('somos')
            anchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }
    const toVentajas = () => {
        const anchor = document.getElementById('ventajas-index')
        if (anchor !== null){
            console.log('ventajas')
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }
    const toTutoriales = () => {
        const anchor = document.getElementById('tutoriales-index')
        if (anchor !== null){
            console.log('tutoriales')
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }
    const toggleNav = () =>{
        setToggle(!toggle);
    }

    return(
        <div>
            <Navbar style={{backgroundColor: 'white'}}
                expand="md"
                fixed="top"
                light
            >
                <NavbarBrand href="/">
                    <NavItem style={{listStyle: 'none'}}>
                        <img style={{width: '80px',
                            paddingLeft: 2+'em'}} src={logoGetVak} alt="logo Get Vak"/>
                    </NavItem>
                </NavbarBrand>
                <NavbarToggler onClick={toggleNav} />
                <Collapse navbar isOpen={toggle} style={{textAlign: 'center'}}>

                    <Nav navbar>
                        <NavItem>
                            <NavLink onClick={toCuerpo}>Inicio</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink onClick={toSomos}>¿Quiénes Somos?</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink onClick={toVentajas}>Ventajas</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink onClick={toTutoriales}>Tutoriales</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink onClick={toContact}>Contacto</NavLink>
                        </NavItem>

                    </Nav>
                </Collapse>
            </Navbar>
        </div>
    )
}


export default HeaderGetVak;
