import {Action, dataInitializationGlobal} from "../const";
import {GET_GLOBAL} from "../types";

const reducerGlobal = (state = dataInitializationGlobal, action: Action) => {
        switch(action.type){
            case GET_GLOBAL:
                return {...state, data: action.payload}
            default:
                return state
        }
}

export {
    reducerGlobal
}
