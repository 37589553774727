import './form-contact.css';
import React from "react";
import FormHook from "./form-hook";
import img from "../../static/img/contact-1.png"
class FormContactGetVak extends React.Component{


    render(): any{
        return (
            <div className="form-contact">
                <section className="contact-section">
                    <div className="auto-container">
                        <div className="row align-items-center clearfix">
                            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                                <div className="image_block_1">
                                    <div className="image-box">
                                        <div className="pattern">
                                            <div className="pattern-1"></div>
                                            <div className="pattern-2"></div>
                                            <div className="pattern-3"></div>
                                        </div>
                                        <figure className="image clearfix"><img src={img} alt=""/>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                                <div className="content_block_1">
                                    <div className="content-box">
                                        <div className="sec-title">
                                            <h2 style={{marginTop: '1em', color: 'rgb(47, 121, 155)', fontFamily: 'Jost, Italic', fontSize: '40px'}}>¿Interesado en implementar GetVac en tu proceso de vacunación?</h2>
                                        </div>
                                        <div className="text">
                                            <p>Envíanos tus datos de contacto y un profesional le contactará a la
                                                brevedad.</p>
                                        </div>
                                        <div className="contact-form-contatiner">
                                            <FormHook></FormHook>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default FormContactGetVak;
