import React, {useRef, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import * as yup from "yup";
import {Button, TextField} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import {yupResolver} from "@hookform/resolvers/yup";
import emailjs from "emailjs-com";
import {Toast} from "primereact/toast";
interface IFormInputs {
    name: string,
    phone: string,
    email: string,
    empresa:string,
    cargo: string,
    identificacion: number,

}
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const schema = yup.object({
    name: yup.string().typeError("Error en el campo Nombre (Solo Letras)").min(6).required("Nombre Requerido"),
    phone: yup.string().matches(phoneRegExp, 'Numero de telefono no es valido'),
    email:  yup.string().email('Must be a valid email').max(255).required('Correo es requerido'),
    empresa: yup.string().required('Empresa es requerido'),
    cargo: yup.string().required('Cargo es requerido'),
    identificacion: yup.number().typeError("Error en identificacion (Solo Numeros)").required(),
}).required()


export default function FormHook() {
    const { register, handleSubmit, formState: { errors } } = useForm<IFormInputs>({resolver:yupResolver(schema)});
    const onSubmit: SubmitHandler<IFormInputs> = data => sendEmail(data);
    const captcha: any = useRef(null);
    const toast: any = useRef(null);
    const [show, setShow] = useState(false);

    // watch input value by passing the name of it

    const sendEmail = (data: any) => {
            emailjs.init("user_i6X7mI2POjwDcNISrZE2M")
            emailjs.send("service_j0ah2q8","template_6ouz14a",{
                to_name: "Get-Vak",
                phone: data.phone,
                name: data.name,
                empresa: data.empresa,
                email: data.email,
                cargo: data.cargo,
                identificacion: data.identificacion
            } ).then((result)=>{
                toast.current.show({severity: 'success', summary: 'Enviado', detail: 'Se Envio Mensaje, Te estaremos Contactando'});
            }, (error) => {
                toast.current.show({severity: 'error', summary: 'Error al Enviar', detail: 'Por favor Contacta con Soporte En la pagina de Zensa'});
            });
    }

    const showErrors = (errorsA:any) => {
        if (toast.current !== null){
        toast.current.show(errorsA);
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <TextField className={"form-left"}  id="name" label="Nombre" variant="outlined" {...register("name")}/>
                {errors.name? showErrors({severity: 'error', summary: 'Error en Nombre', detail: errors.name.message}):null}
                <TextField id="identificacion" label="Identificacion" variant="outlined" {...register("identificacion")}/>
                {errors.identificacion? showErrors({severity: 'error', summary: 'Error en Identicacion', detail: errors.identificacion.message}):null}
                <hr/>
                <TextField className={"form-left"} id="phone" label="Telefono" variant="outlined" {...register("phone")}/>
                {errors.phone? showErrors({severity: 'error', summary: 'Error en Telefono', detail: errors.phone.message}):null}
                <TextField id="email" label="Correo" variant="outlined" {...register("email")}/>
                {errors.email? showErrors({severity: 'error', summary: 'Error en Correo', detail: errors.email.message}):null}
                <hr/>
                <TextField className={"form-left"}  id="empresa" label="Empresa" variant="outlined" {...register("empresa")}/>
                {errors.empresa? showErrors({severity: 'error', summary: 'Error en Empresa', detail: errors.empresa.message}):null}
                <TextField id="cargo" label="Cargo" variant="outlined" {...register("cargo")}/>
                {errors.cargo? showErrors({severity: 'error', summary: 'Error en Cargo', detail: errors.cargo.message}):null}
                <hr/>
                <div className="recaptcha">
                    <ReCAPTCHA
                        ref={captcha}
                        onChange={() => setShow(true)}
                        onExpired={() => setShow(false)}
                        sitekey="6Ld7riMdAAAAAFJ6c-wKIB3Ivz3ryiuByPItPOva">
                    </ReCAPTCHA>
                </div>
                <hr/>
                {show? <Button style={{width: '100%'}} variant="contained" type={"submit"} value={"submit"}>Enviar</Button>:
                    <Button style={{width: '100%'}} disabled variant="contained" type={"submit"} value={"submit"}>Enviar</Button>}
            </form>
            <Toast ref={toast} />
        </div>
        /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    );
}
