import {Col, Row} from "reactstrap";
import "./lading.css";
import ContentGetVak from "../content";
import FooterGetVak from "../footer";
import HeaderGetVak from "../header";
function Lading(): JSX.Element {
    return(
        <div className="landing-container">
            <Row>
                <Col md={12} sm={12} xs={12} className={"col-landing-header"}>
                    <HeaderGetVak></HeaderGetVak>
                </Col>
            </Row>
            <Row>
                <Col md={12} sm={12} xs={12} className={"col-landing-content"}>
                    <ContentGetVak></ContentGetVak>
                </Col>
            </Row>
            <Row>
                <Col md={12} sm={12} xs={12} className={"col-landing-footer"}>
                    <FooterGetVak></FooterGetVak>
                </Col>
            </Row>
        </div>
    )
}


export default Lading;
