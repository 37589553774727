

const dataInitializationGlobal = {
    errors: []
}

interface Action  {
    type: String,
    payload: any
}

export {
    dataInitializationGlobal
};
export type { Action };

