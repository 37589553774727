import FormContactGetVak from "../../components/form-contact";
import "./content.css"
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Row
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle} from '@fortawesome/free-regular-svg-icons'
import {faAppStore, faGooglePlay} from '@fortawesome/free-brands-svg-icons'
import banner from "../../static/img/banner-image-1.png";
import React, {useState} from "react";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import ReactPlayer from 'react-player'
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import SwiperCore, {
    Navigation,
    Pagination,
    Autoplay,
} from 'swiper';
import vaccine from '../../static/img/vaccine.png';
import doctor from '../../static/img/doctor.png';
import paper from '../../static/img/medical-prescription.png';
import data from '../../static/img/research.png';
import programmer from '../../static/img/programmer.png';
import calendar from '../../static/img/schedule.png';
import wifi from '../../static/img/wifi.png';
import clipboard from '../../static/img/health-report.png';
import doctorOne from '../../static/img/chemist.png';
import paciente from '../../static/img/fever.png';
import manos from '../../static/img/approve.png';


SwiperCore.use([Autoplay,Navigation,Pagination]);
function ContentGetVak(): JSX.Element {

    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [position, setPosition] = useState('center');
    const [urlString, setUrlString] = useState('');
    const onClick = (url:any) => {
        setDisplayMaximizable(true);
        setUrlString(url);
        if (position) {
            setPosition(position);
        }
    }
    const renderFooter = () => {
        return (
            <div>
                <Button label="Cerrar" icon="pi pi-check" onClick={() => onHide()} autoFocus />
            </div>
        );
    }

    const onHide = () => {
        setDisplayMaximizable(false)
    }
    return(
        <div className="content-index">
            <div id="cuerpo-content-index" className={"background-principal"} >
                <Col md={12} sm={12} xs={12} >
                    <Row className={"title-init-container"}>
                        <Col style={{margin: 'auto 0px'}} md={6} sm={12}>
                            <p className={"title-init z-index-1-relative"}>Vacunar hoy, controlar el COVID-19 es una prioridad.</p>
                            <p className={"z-index-1-relative"} style={{fontSize: 21+'px', fontFamily: '\'Poppins\', sans-serif'}}>Para agilizar esto, debemos integrar la tecnología.</p>
                        </Col>
                        <Col style={{textAlign: 'center'}} md={6} sm={12}>
                            <img className={"banner-image"} src={banner} alt="logo del banner"/>
                        </Col>
                    </Row>
                </Col>
            </div>
            <div className="cuerpo-content" id="quienes-somos-index">
                <section className="about-section bg-color-3">
                    <div className="bg-layer" ></div>
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="col-xl-7 col-lg-12 col-md-12 inner-column">
                                <div className="about-inner">

                                    <div className="pattern"
                                         style={{backgroundImage: 'url(assets/images/shape/shape-21.png)'}}></div>
                                    <div className="sec-title">
                                        <h2 className="about-title" style={{marginTop:'1em',color: 'rgb(47 121 155)', WebkitTextStroke: 1+'px ', fontFamily: 'Jost, Italic', fontSize: 40+'px'}}>¿Quiénes Somos?</h2>
                                    </div>
                                    <div className="content">
                                        <p style={{fontSize: 21+'px', fontFamily: '\'Poppins\', sans-serif'}}>
                                            Es un desarrollo de <a href="https://getvac">Zensa medica</a> para
                                            integrar las herramientas digitales en la vacunación contra el Covid-19.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Row id="ventajas-index" style={{backgroundColor:'white', position:"relative"}}>
                    <div className={"image-shape-21"}></div>
                    <div className={"image-shape-21-2"}></div>
                    <Col md={12} sm={12} xs={12} style={{textAlign:'center'}}>
                        <h3 style={{marginTop:'1em',color: '#2f799b', WebkitTextStroke: 1+'px ', fontFamily: 'Jost, Italic', fontSize: 40+'px'}}>VENTAJAS</h3>
                    </Col>
                    <Col md={12} sm={12} xs={12} style={{padding: '3em'}}>
                        <Card style={{ width: '100%', height: '100%' , border: 'none'}}>
                            <CardBody style={{    display: 'flex', flexWrap: 'wrap'}}>
                                <div className={"content-ventajas"}>
                                    <img className={"img-ventajas"} src={vaccine} alt="vacunacion"/>
                                    <p className={"p-ventajas"}>Menor tiempo de vacunación</p>
                                </div>
                                <div className={"content-ventajas"}>
                                    <img className={"img-ventajas"} src={doctor} alt="vacunacion"/>
                                    <p className={"p-ventajas"}>Ahorro de recursos humanos y físicos.</p>
                                </div>
                                <div className={"content-ventajas"}>
                                    <img className={"img-ventajas"} src={paper} alt="vacunacion"/>
                                    <p className={"p-ventajas"}>Base de datos digitalizada.</p>
                                </div>
                                <div className={"content-ventajas"}>
                                    <img className={"img-ventajas"} src={data} alt="vacunacion"/>
                                    <p className={"p-ventajas"}>Reducción de tiempo y papeleo.</p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row style={{backgroundColor:'rgb(235, 247, 246)', padding: '2em 0px'}}>
                    <Col  md={12} sm={12} xs={12}>
                        <Swiper
                            style={{backgroundColor: 'white',
                                borderRadius: '0.3em',
                                marginLeft: '1em',
                                marginRight: '1em'}}
                            autoplay={{
                                "delay": 2500,
                                "disableOnInteraction": false
                            }}
                            spaceBetween={50}
                            slidesPerView={1}
                            navigation={true}
                            centeredSlides={true}
                            pagination={{ clickable: true,
                                "dynamicBullets": true }}>
                            <SwiperSlide style={{ height: '25em', textAlign: 'center', color:'rgb(55 100 114)'}}>
                                <Row style={{ width: '80%', height: '100%', margin: '0 auto'}}>
                                    <Col md={12} sm={12} xs={12} style={{paddingTop: '2em'}}>
                                        <p className={"jost-title"}>Usando herramientas como</p>
                                    </Col>
                                    <Col md={4} sm={4} xs={4}>
                                        <img className={"img-slader img-programmer"} src={programmer} alt="vacunacion"/>
                                        <p className={"p-slide"}>Auto Gestion Digital del Usuario</p>
                                    </Col>
                                    <Col  md={4} sm={4} xs={4}>

                                        <Row  className={"row-img"}>
                                            <Col md={12} sm={12} xs={12}>
                                                <img className={"img-slader img-wifi"} src={wifi} alt="vacunacion"/>
                                            </Col>
                                            <Col md={12} sm={12} xs={12}>
                                                <img className={"img-slader img-calendar"}  src={calendar} alt="vacunacion"/>
                                            </Col>
                                        </Row>
                                        <p className={"p-slide"}>Asignación Web de Citas Con Geo-localización</p>
                                    </Col>
                                    <Col md={4} sm={4} xs={4}>
                                        <div className={"div-img-3"}>
                                            <img className={"img-slader"} src={clipboard} alt="vacunacion"/>
                                        </div>
                                        <p className={"p-slide"}>Regitro Digital de Vacunacion Fisica</p>
                                    </Col>
                                </Row>
                            </SwiperSlide>
                            <SwiperSlide style={{ height: '25em', textAlign: 'initial'}}>
                                <div style={{ padding: '5em 0em 0em 0em',width: '80%', height: '100%', margin: 'auto auto'}}>
                                    <Row>
                                        <Col md={12} sm={12} xs={12}><p className={"jost-title"}>Aplicativo paciente</p></Col>
                                        <Col  md={8} sm={8} xs={8}>
                                            <p className={"p-slide-2"}>
                                                Escanea su cedula (evitando errores de identificacion),
                                                registra sus antecedentes, y consentimiento informado.
                                                Agendar citas y notificar efectos secundarios y/o reacciones adversas.
                                            </p>
                                        </Col>
                                        <Col md={4} sm={4} xs={4}>
                                            <img className={"img-slader-2"} src={paciente} alt="vacunacion"/>
                                        </Col>
                                    </Row>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide style={{ height: '25em', textAlign: 'initial'}}>
                                <div style={{padding: '4em 0em 0em 0em' ,width: '80%', height: '100%', margin: '0 auto'}}>
                                    <Row>
                                        <Col md={12} sm={12} xs={12}><p className={"jost-title"}>Aplicativo vacunador</p></Col>
                                        <Col  md={7} sm={7} xs={7}>
                                            <p className={"p-slide-2"}>
                                                Le permite al profesional hacer llamado por turno digital,
                                                veirifar la identidad del paciente , revisar los registros de vaunación,
                                                todo esto, con unos pocos clicks
                                            </p>
                                        </Col>
                                        <Col md={5} sm={5} xs={5}>
                                            <img className={"img-slader-2"} src={doctorOne} alt="vacunacion"/>
                                        </Col>
                                    </Row>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide style={{ height: '25em', textAlign: 'initial'}}>
                                <div style={{padding: '4em 0em 0em 0em' ,width: '80%', height: '100%', margin: '0 auto'}}>
                                    <Row>
                                        <Col md={12} sm={12} xs={12}><p className={"jost-title"}>Producto final</p></Col>
                                        <Col  md={7} sm={7} xs={7}>
                                            <p className={"p-slide-2"}>
                                                Vacunacion ágil
                                                Bases de datos e historias
                                                clinicas digitales con ingreso automatizado a las
                                                plataformas de PAI web y Covimetro.
                                                Reporte en tiempo real de efectos seundarios y/o
                                                reacciones adversas.
                                            </p>
                                        </Col>
                                        <Col md={5} sm={5} xs={5}>
                                            <img className={"img-slader-2"} src={manos} alt="vacunacion"/>
                                        </Col>
                                    </Row>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </Col>
                </Row>
                <Row id="tutoriales-index" style={{backgroundColor: 'rgb(183 233 225)',
                    position: 'relative', paddingBottom:'5em'}}>
                    <div className={"shape-img-tutoriales-1"}></div>
                    <div className={"shape-img-tutoriales-2"}></div>
                    <Col style={{padding: '.5em', textAlign: 'center', paddingTop: '3em'}} md={12} sm={12} xs={12}>
                        <h3 style={{color: 'white', WebkitTextStroke: 1+'px ', fontFamily: 'Jost, Italic', fontSize: 40+'px'}}>TUTORIALES</h3>
                    </Col>
                    <Col style={{padding: '.5em', margin: '0 auto'}} md={4} sm={7} xs={12}>
                        <Card style={{ width: '100%', height: '100%' , borderRadius:'0.65rem'}}>
                            <CardTitle style={{textAlign:'center'}}><h3 style={{color: '#00224f', WebkitTextStroke: 1+'px ', fontFamily: 'Jost, Italic', fontSize: 35+'px'}}>Paciente</h3></CardTitle>
                            <CardBody style={{padding: '.5em' ,display: 'flex', flexWrap: 'wrap'}}>
                                <div onClick={() => onClick('https://youtu.be/VVT3OqWRWiA')} style={{width: '100%', height:'8em', textAlign:'center'}} className={"video-one"}>
                                    <FontAwesomeIcon  style={{color: 'rgba(0, 34, 79, 0.7)', fontSize: '8em'}} icon={faPlayCircle} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col  style={{padding: '.5em', margin: '0 auto'}} md={4} sm={7} xs={12}>
                        <Card style={{ width: '100%', height: '100%' , borderRadius:'0.65rem'}}>
                            <CardTitle style={{textAlign:'center'}}><h3 style={{color: '#00224f', WebkitTextStroke: 1+'px ', fontFamily: 'Jost, Italic', fontSize: 35+'px'}}>Vacunador</h3></CardTitle>
                            <CardBody style={{ padding: '.5em' ,display: 'flex', flexWrap: 'wrap'}}>
                                <div onClick={() => onClick('https://youtu.be/fRSIsmGHJaI')} style={{width: '100%', height:'8em', textAlign:'center'}} className={"video-two"}>
                                    <FontAwesomeIcon style={{color: 'rgba(0, 34, 79, 0.7)', fontSize: '8em'}} icon={faPlayCircle} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col  style={{padding: '.5em', margin: '0 auto'}} md={4} sm={7} xs={12}>
                        <Card style={{ width: '100%', height: '100%' , borderRadius:'0.65rem'}}>
                            <CardTitle style={{textAlign:'center'}}><h3 style={{color: '#00224f', WebkitTextStroke: 1+'px ', fontFamily: 'Jost, Italic', fontSize: 35+'px'}}>Recepcionista</h3></CardTitle>
                            <CardBody style={{padding: '.5em' ,display: 'flex', flexWrap: 'wrap'}}>
                                <div onClick={() => onClick('https://youtu.be/pBl7rhBJPBI')} style={{width: '100%', height:'8em', textAlign:'center'}} className={"video-three"}>
                                    <FontAwesomeIcon style={{color: 'rgba(0, 34, 79, 0.7)', fontSize: '8em'}} icon={faPlayCircle} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row id="contact-content-index" style={{backgroundColor:'white', position:"relative"}}>
                    <div className={"image-shape-21-2-1"}></div>
                    <div className={"image-shape-21-2-2"}></div>
                    <div className={"image-shape-21-2-3"}></div>
                    <Col md={12} sm={12} xs={12} style={{textAlign: 'center'}}>
                        <div>
                            <FormContactGetVak ></FormContactGetVak>
                        </div>
                    </Col>
                </Row>
                <Row style={{backgroundColor:'#453f85', position:"relative", paddingTop:'4em'}}>
                    <Col style={{textAlign:'center'}} md={12} sm={12} xs={12}>
                        <p style={{    fontSize: 26+'px',
                            fontWeight: 'bold',
                            color: 'white', fontFamily: '\'Poppins\', sans-serif'}}>Descarga nuestras Apps.</p>
                    </Col>
                </Row>
                <Row style={{backgroundColor:'#453f85', position:"relative"}}>
                    <Col className={"hide-models"} md={3} sm={12} xs={12}>

                    </Col>
                    <Col md={3} sm={12} xs={12}>
                        <Row style={{paddingBottom: 1+'em',backgroundColor:'#453f85', position:"relative"}}>
                            <Col style={{textAlign:'center'}} md={12} sm={12} xs={12}>
                                <p style={{    fontSize: 26+'px',
                                    fontWeight: 'bold',
                                    color: 'white', fontFamily: '\'Poppins\', sans-serif'}}>Aplicacion Usuario</p>
                            </Col>
                            <Col style={{textAlign:'center', paddingBottom:'1em'}} md={12} sm={12} xs={12}>
                                <a className={"btn"} style={{borderRadius:'2.24rem',textAlign: 'initial', backgroundColor:'#04224f', padding:'1em'}} href="https://apps.apple.com/co/app/getvac/id1570730925">
                                    <Row>
                                        <Col style={{width: 'max-content', paddingRight:'0px'}} md={4} sm={4} xs={4}>
                                            <FontAwesomeIcon icon={faAppStore} />
                                        </Col>
                                        <Col style={{width: 'min-content'}} md={8} sm={8} xs={8}>
                                            <Row>
                                                <Col style={{height: '1em',width: 'max-content'}} md={12} sm={12} xs={12}>
                                                    <p className={"p-icon"}>Downlod in</p></Col>
                                                <Col style={{height: '1em',width: 'max-content'}} md={12} sm={12} xs={12}>
                                                    <p style={{fontSize:'1.4em'}} className={"p-icon"}>AppStore</p></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </a>
                            </Col>
                            <Col style={{textAlign:'center'}} md={12} sm={12} xs={12}>
                                <a className={"btn"} style={{borderRadius:'2.24rem',textAlign: 'initial', backgroundColor:'rgb(47 121 155)', padding:'1em'}} href="https://play.google.com/store/apps/details?id=co.getvac.getvacpaciente">
                                    <Row>
                                        <Col style={{width: 'max-content', paddingRight:'0px'}} md={4} sm={4} xs={4}>
                                            <FontAwesomeIcon icon={faGooglePlay} />
                                        </Col>
                                        <Col style={{width: 'min-content'}} md={8} sm={8} xs={8}>
                                            <Row>
                                                <Col style={{height: '1em',width: 'max-content'}} md={12} sm={12} xs={12}>
                                                    <p className={"p-icon"}>Downlod in</p></Col>
                                                <Col style={{height: '1em',width: 'max-content'}} md={12} sm={12} xs={12}>
                                                    <p style={{fontSize:'1.4em'}} className={"p-icon"}>PlayStore</p></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </a>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3} sm={12} xs={12}>
                        <Row style={{paddingBottom: 4+'em',backgroundColor:'#453f85', position:"relative"}}>
                            <Col style={{textAlign:'center'}} md={12} sm={12} xs={12}>
                                <p style={{    fontSize: 26+'px',
                                    fontWeight: 'bold',
                                    color: 'white', fontFamily: '\'Poppins\', sans-serif'}}>Aplicacion Vacunador</p>
                            </Col>
                            <Col style={{textAlign:'center', paddingBottom:'1em'}} md={12} sm={12} xs={12}>
                                <a className={"btn"} style={{borderRadius:'2.24rem',textAlign: 'initial', backgroundColor:'#04224f', padding:'1em'}} href="https://apps.apple.com/co/app/getvac-vacunador/id1582369529">
                                    <Row>
                                        <Col style={{width: 'max-content', paddingRight:'0px'}} md={4} sm={4} xs={4}>
                                            <FontAwesomeIcon icon={faAppStore} />
                                        </Col>
                                        <Col style={{width: 'min-content'}} md={8} sm={8} xs={8}>
                                            <Row>
                                                <Col style={{height: '1em',width: 'max-content'}} md={12} sm={12} xs={12}>
                                                    <p className={"p-icon"}>Downlod in</p></Col>
                                                <Col style={{height: '1em',width: 'max-content'}} md={12} sm={12} xs={12}>
                                                    <p style={{fontSize:'1.4em'}} className={"p-icon"}>AppStore</p></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </a>
                            </Col>
                            <Col style={{textAlign:'center'}} md={12} sm={12} xs={12}>
                                <a className={"btn"} style={{borderRadius:'2.24rem',textAlign: 'initial', backgroundColor:'rgb(47 121 155)', padding:'1em'}} href="https://play.google.com/store/apps/details?id=co.getvac.getvacpaciente">
                                    <Row>
                                        <Col style={{width: 'max-content', paddingRight:'0px'}} md={4} sm={4} xs={4}>
                                            <FontAwesomeIcon icon={faGooglePlay} />
                                        </Col>
                                        <Col style={{width: 'min-content'}} md={8} sm={8} xs={8}>
                                            <Row>
                                                <Col style={{height: '1em',width: 'max-content'}} md={12} sm={12} xs={12}>
                                                    <p className={"p-icon"}>Downlod in</p></Col>
                                                <Col style={{height: '1em',width: 'max-content'}} md={12} sm={12} xs={12}>
                                                    <p style={{fontSize:'1.4em'}} className={"p-icon"}>PlayStore</p></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </a>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3} sm={12} xs={12}>

                    </Col>
                </Row>
            </div>
            <Dialog header="Tutorial" visible={displayMaximizable} maximizable modal style={{ width: '90vw', height: '100vw' }} footer={renderFooter()} onHide={() => onHide()}>
                <ReactPlayer
                    url={urlString}
                    className='react-player'
                    controls
                    width='100%'
                    height='100%'
                />
            </Dialog>
        </div>
    )
}


export default ContentGetVak;
