import {createStore, combineReducers, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'
import {reducerGlobal} from "../reducers";


const rootReducer = combineReducers({
    show: reducerGlobal
})

const generateStore = () => {
    const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
    return store
}

export default generateStore;
