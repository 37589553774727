import logoZensa from "../../static/img/GetVac/senza.png";

function FooterGetVak(): JSX.Element {
    return(
        <div className="footer-index" style={{textAlign: 'center',margin: .5+'em'}}>
            <img style={{width:3+'em', display:'inline', marginRight: .5+'em'}} src={logoZensa} alt="Logo Get Vak"/>
            <h3 style={{display:"inline", color: 'white'}}>Zensa Medica</h3>
        </div>
    )
}


export default FooterGetVak;
