import React from 'react';
import './App.css';
import {Provider} from "react-redux";
import generateStore from "../redux/stores";
import Lading from "../views/lading";

function App() {

  const store = generateStore()
  return (
    <Provider store={store}>
        <Lading></Lading>
    </Provider>
  );
}

export default App;
